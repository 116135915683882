import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b24d9634 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _2f8538ea = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _2c77d83e = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _618d9e7d = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _41561734 = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _32d20491 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _f80d76fc = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _6db85e76 = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _524191a2 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _4ff448e7 = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _0eeefd44 = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _a60b21c2 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _4397e158 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _4ebf0614 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _2401a414 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _303a4d4c = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _1aedf93a = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _2c9cb730 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _427df028 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _7b43abc1 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _69cb1ab7 = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _2b670b37 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _e0093eb6 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _42b19ebc = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _18afd130 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _2def5b5e = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _0ffb7d34 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _9e9edec6 = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _e4bba530 = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _2cf9493e = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _26bfef67 = () => interopDefault(import('../client/pages/sso/punch-out/_source.vue' /* webpackChunkName: "pages/sso/punch-out/_source" */))
const _f2a15f8a = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _30fc0be0 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _e282f708 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _42e768e6 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _067e4cce = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _51db109e = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _ab5c28bc = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _baab55de = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _1bb84e6d = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _bcebefa2 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _22753780 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _75576946 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _0b5ab8f9 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _d9778dd4 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _673fb3bc = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _67218fa2 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _49761494 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _7445e1a9 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _22ca8c65 = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _b8821202 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _5d5404ab = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _c56fed94 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _78916bd0 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _8380960e = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _16096e56 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _86a5706a = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _2b865853 = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _49580f24 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _5c9c2980 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _35132dbf = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _21696aae = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _24da746c = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _b24d9634,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _b24d9634,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _b24d9634,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _b24d9634,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _b24d9634,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _b24d9634,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _b24d9634,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _b24d9634,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _2f8538ea,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _2c77d83e,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _618d9e7d,
    name: "account"
  }, {
    path: "/cart",
    component: _b24d9634,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _41561734,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _32d20491,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _f80d76fc,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _6db85e76,
    name: "designs"
  }, {
    path: "/facets",
    component: _524191a2,
    name: "facets"
  }, {
    path: "/favorites",
    component: _4ff448e7,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _0eeefd44,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _a60b21c2,
    name: "health"
  }, {
    path: "/not-found",
    component: _4397e158,
    name: "not-found"
  }, {
    path: "/proof",
    component: _4ebf0614,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _2401a414,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _303a4d4c,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _1aedf93a,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _2c9cb730,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _427df028,
    name: "signin"
  }, {
    path: "/signout",
    component: _7b43abc1,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _69cb1ab7,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _2b670b37,
    name: "testy"
  }, {
    path: "/version",
    component: _e0093eb6,
    name: "version"
  }, {
    path: "/version-app",
    component: _42b19ebc,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _18afd130,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _2def5b5e,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _0ffb7d34,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _9e9edec6,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _e4bba530,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _2cf9493e,
    name: "sso-facebook-delete"
  }, {
    path: "/sso/punch-out/:source?",
    component: _26bfef67,
    name: "sso-punch-out-source"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _f2a15f8a,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _30fc0be0,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _2f8538ea,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _2c77d83e,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _e282f708,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _42e768e6,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _067e4cce,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _51db109e,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _ab5c28bc,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _baab55de,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _1bb84e6d,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _bcebefa2,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _22753780,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _75576946,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _0b5ab8f9,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _d9778dd4,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _673fb3bc,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _67218fa2,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _49761494,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _7445e1a9,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _22ca8c65,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _b8821202,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _5d5404ab,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _c56fed94,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _78916bd0,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _8380960e,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _16096e56,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _86a5706a,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _2b865853,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _49580f24,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _5c9c2980,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _35132dbf,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _21696aae,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _24da746c,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
